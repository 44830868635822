import styled from 'styled-components';
import Input from 'src/components/Input';
import CustomerSelectMulti from 'src/components/CustomerSelectMulti';
import Button from 'src/components/Button';
import Select from 'react-select';

export const Grid = styled.div`
  display: grid;
  padding: 10px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: calc(100% - 75px);
  height: calc(100vh - 75px);
`;

export const FilterContainer = styled.div`
  width: calc(100% - 20px);
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  margin: 10px 10px 0px 10px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.text};
`;

export const ClientInput = styled(Input).attrs({
  placeholder: 'clientes',
  id: 'client',
  type: 'text',
  autocomplete: 'on',
  margin: '25px 8px 0 0',
  width: '180px',
  height: '30px',
})`
  font-size: 16px;
`;

export const MotoboyInput = styled(Input).attrs({
  placeholder: 'motoqueiros',
  id: 'motoboy',
  type: 'text',
  autocomplete: 'on',
  margin: '25px 8px 0 0',
  width: '170px',
})`
  font-size: 16px;
`;

export const StartDateInput = styled(Input).attrs({
  placeholder: 'data inicial',
  id: 'motoboy',
  type: 'date',
  autocomplete: 'on',
  margin: '25px 8px 0 0',
  width: '170px',
})`
  font-size: 16px;
  height: 39px;
`;

export const EndDateInput = styled(Input).attrs({
  placeholder: 'data final',
  id: 'motoboy',
  type: 'date',
  autocomplete: 'on',
  margin: '25px 8px 0 0',
  width: '170px',
})`
  font-size: 16px;
  height: 39px;
`;

export const ButtonCard = styled(Flex)`
  margin: 25px 8px 0 0;
`;

export const ExportButton = styled(Button)`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  font-size: 12px;
  width: 100px;
`;

export const ExportCard = styled(Flex)`
  /* margin: 25px 8px 0 0; */
  /* background-color: ${({ theme }) => theme.colors.background}; */
  padding: 5px;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
`;

export const Customer = styled(CustomerSelectMulti).attrs({
  margin: '25px 8px 0 0',
  width: '200px',
  height: '30px',
  fontSize: '14px',
  label: 'clientes',
})`
  height: 30px !important;
`;

export const ValueFromSelect = styled(Select).attrs({
  margin: '25px 8px 0 0',
  width: '280px',
  height: '30px',
  fontSize: '14px',
  label: 'valor',
})``;

export const ExportColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExportMessage = styled.label`
  font-size: 9px !important;
  text-align: center;
  color: #fff;
`;

interface IStatusButton {
  active: boolean;
}

export const StatusButton = styled.button<IStatusButton>`
  padding: 8px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.secondary : theme.colors.disabled};
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.textLight)};
  border-radius: 15px;
  cursor: pointer;
  border: none;
`;
