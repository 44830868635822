import { Container } from './styles';
import React from 'react';
import { ThreeDots } from 'src/components/ThreeDots';
import { useTheme } from 'styled-components';

interface Props {
  color?: string;
}

const Loader: React.FC<Props> = ({ color }) => {
  const { colors } = useTheme();

  return (
    <Container>
      <ThreeDots color={color ?? colors.primary} height={50} width={50} />
    </Container>
  );
};

export default Loader;
