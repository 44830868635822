import styled, { css } from 'styled-components';

interface ContainerProps {
  width?: string;
  margin?: string;
}
export const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${({ width }) => width ?? '400px'};
  margin: ${({ margin }) => margin ?? '15px 0px 0px'};
  display: flex;
  max-height: 40px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  color: ${({ theme }) => theme.colors.secondary};
`;

export const InputText = styled.input`
  width: 100%;
  padding: 10px;
  text-align: center;

  border: transparent;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.textLight};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `};
`;
